import React, { Component } from 'react';
import styles from "./Button.module.css"

class Button extends Component {

    render() {
        const { label, onClick, disabled } = this.props
        let classes = styles.Button;
        if (this.props.type === 'alt' || this.props.type === 'power') {
            classes += ` ${styles.alt}`
        }
        if (this.props.type === 'power') {
            classes += ` ${styles.power}`
        }
        return (
            <div className={classes}>
                { (this.props.type === 'power') ? (<div className={styles.onLabel}>ON</div>) : '' }
                { (this.props.type === 'power') ? (<div className={styles.powerNub}></div>) : '' }
                <button type="button" onClick={onClick} disabled={disabled}>{label||'??'}</button>
            </div>
        )
    }
}

export default Button;
