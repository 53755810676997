import './App.css';
import Button from './components/Button';
import Header from './components/Header';
import Screen from './components/Screen';
import { useState } from 'react';

// Functional Component
function App() {

  const [isPoweredOn, updatePoweredOn] = useState(false)
  const [offClickCount, updateOffClickCount] = useState(0)

  const INPUT_MODE = {
    NUMBER: 'NUMBER',
    OPERATOR: 'OPERATOR'
  };
  const [inputMode, updateInputMode] = useState(INPUT_MODE.NUMBER);

  const DISPLAY_MODE = {
    NUMBER: 'NUMBER',
    MEMORY: 'MEMORY',
  }
  const [displayMode, updateDisplayMode] = useState(DISPLAY_MODE.NUMBER)


  const [numberInMemory, updateNumberInMemory] = useState(0);
  const [decimalInMemory, updateDecimalInMemory] = useState(0);
  const [decUsageInMemory, updateDecUsageInMemory] = useState(false);

  const [numberInDisplay, updateNumberInDisplay] = useState(0);
  const [decimalInDisplay, updateDecimalInDisplay] = useState(0);
  const [decUsageInDisplay, updateDecUsageInDisplay] = useState(false);
  
  const [operatorInMemory, updateOperatorInMemory] = useState(null);

  const [displayedNumber, updateDisplayedNumber] = useState('')

  const pressed = action => {

    console.log("pressed(action) = %s", action);

    let newPoweredOn = isPoweredOn;
    let newOffClickCount = offClickCount;

    let newNumberInMemory = numberInMemory;
    let newDecimalInMemory = decimalInMemory;
    let newDecUsageInMemory = decUsageInMemory;

    let newNumberInDisplay = numberInDisplay;
    let newDecimalInDisplay = decimalInDisplay;
    let newDecUsageInDisplay = decUsageInDisplay;

    let newInputMode = inputMode
    let newDisplayMode = displayMode

    // This button is a global reset.
    if (action === "AC") {
        newPoweredOn = true
        newInputMode = INPUT_MODE.NUMBER
        newDisplayMode = DISPLAY_MODE.NUMBER

        newNumberInDisplay = 0
        newDecimalInDisplay = 0
        newDecUsageInDisplay = false

        newNumberInMemory = 0
        newDecimalInMemory = 0
        newDecUsageInMemory = false

        updateOperatorInMemory(null)
    }

    if (newPoweredOn === false && action !== "AC") {
      newOffClickCount += 1
    }

    switch (action) {
        case "AC":
            break;

        case "C":
            newInputMode = INPUT_MODE.NUMBER
            newDisplayMode = DISPLAY_MODE.NUMBER
            newNumberInDisplay = 0
            newDecimalInDisplay = 0
            newDecUsageInDisplay = false

            break;

        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
            newInputMode = INPUT_MODE.NUMBER
            newDisplayMode = DISPLAY_MODE.NUMBER

            // User is prevented from entering too big of a number.
            if (newNumberInDisplay < 9999999) {
                newNumberInDisplay = newNumberInDisplay*10 + parseInt(action)
                if (newDecUsageInDisplay) {
                    newDecimalInDisplay += 1
                }
            }
            break;

        case "+":
        case "-":
        case "*":
        case "/":
        case "%":
        case "=":
            newInputMode = INPUT_MODE.NUMBER
            newDisplayMode = DISPLAY_MODE.MEMORY

            if (operatorInMemory === null) {
                newNumberInMemory = newNumberInDisplay;
                newDecimalInMemory = newDecimalInDisplay;
                newDecUsageInMemory = newDecUsageInDisplay;

                newNumberInDisplay = 0;
                newDecimalInDisplay = 0
                newDecUsageInDisplay = false

                if (action === "=") {
                    updateOperatorInMemory(null);
                } else {
                    updateOperatorInMemory(action)
                }
            } else {
                // We do math

                let normalizedMemoryNumber = newNumberInMemory;
                let normalizedMemoryDecimal = newDecimalInMemory;
                let normalizedDisplayNumber = newNumberInDisplay;
                let normalizedDisplayDecimal = newDecimalInDisplay;
                
                console.group("normalization")
                console.log("MN:%s MD:%s DN:%s DD:%s", normalizedMemoryNumber, normalizedMemoryDecimal, normalizedDisplayNumber, normalizedDisplayDecimal)
                console.groupEnd()

                while (normalizedMemoryDecimal > normalizedDisplayDecimal) {
                    normalizedDisplayNumber *= 10;
                    normalizedDisplayDecimal += 1
                }
                while (normalizedMemoryDecimal < normalizedDisplayDecimal) {
                    normalizedMemoryNumber *= 10;
                    normalizedMemoryDecimal += 1
                }

                console.group("normalization")
                console.log("MN:%s MD:%s DN:%s DD:%s", normalizedMemoryNumber, normalizedMemoryDecimal, normalizedDisplayNumber, normalizedDisplayDecimal)
                console.groupEnd()

                switch (operatorInMemory) {

                    case "+":
                        normalizedMemoryNumber = normalizedMemoryNumber + normalizedDisplayNumber;
                        break;
                    case "-":
                        normalizedMemoryNumber = normalizedMemoryNumber - normalizedDisplayNumber;
                        break;
                    case "*":
                        normalizedMemoryNumber = normalizedMemoryNumber * normalizedDisplayNumber;
                        normalizedMemoryDecimal += normalizedDisplayDecimal
                        break;
                    case "/":
                        if (newNumberInDisplay === 0) {
                            // ERROR
                        } else {


                            while (normalizedMemoryNumber < normalizedDisplayNumber) {
                                normalizedMemoryNumber *= 10;
                                normalizedMemoryDecimal += 1
                            }


                            console.group("normalization")
                            console.log("MN:%s MD:%s DN:%s DD:%s", normalizedMemoryNumber, normalizedMemoryDecimal, normalizedDisplayNumber, normalizedDisplayDecimal)
                            console.groupEnd()

                            normalizedMemoryNumber = normalizedMemoryNumber / normalizedDisplayNumber;
                            normalizedMemoryDecimal -= normalizedDisplayDecimal
                            while (normalizedMemoryNumber !== Math.floor(normalizedMemoryNumber)) {
                                normalizedMemoryNumber *= 10;
                                if (normalizedMemoryNumber > 9999999) {
                                    normalizedMemoryNumber = Math.floor(normalizedMemoryNumber)
                                    normalizedMemoryDecimal += 1
                                }
                            }
                        }
                        break;
                    case "%":
                        normalizedMemoryNumber = normalizedMemoryNumber * normalizedDisplayNumber;
                        normalizedMemoryDecimal += normalizedDisplayDecimal + 2
                        break;

                    default:
                        //pass

                }
                if (action === "=") {
                    updateOperatorInMemory(null);
                } else {
                    updateOperatorInMemory(action)
                }

                console.group("normalization")
                console.log("MN:%s MD:%s DN:%s DD:%s", normalizedMemoryNumber, normalizedMemoryDecimal, normalizedDisplayNumber, normalizedDisplayDecimal)
                console.groupEnd()

                while (normalizedMemoryDecimal > 0 && normalizedMemoryNumber !== 0 && normalizedMemoryNumber % 10 === 0) {
                    normalizedMemoryNumber = Math.floor(normalizedMemoryNumber / 10)
                    normalizedMemoryDecimal -= 1
                }

                console.group("normalization")
                console.log("MN:%s MD:%s DN:%s DD:%s", normalizedMemoryNumber, normalizedMemoryDecimal, normalizedDisplayNumber, normalizedDisplayDecimal)
                console.groupEnd()

                newNumberInMemory = normalizedMemoryNumber
                newDecimalInMemory = normalizedMemoryDecimal
                newDecUsageInMemory = (normalizedMemoryDecimal > 0)

                newNumberInDisplay = 0
                newDecimalInDisplay = 0
                newDecUsageInDisplay = false
            }

            break;

        case ".":
            if (!newDecUsageInDisplay) {
                newDecUsageInDisplay = true;
                newDecimalInDisplay = 0
            }
            break;

        case "+/-":
            if (newDisplayMode === DISPLAY_MODE.NUMBER) {
                newNumberInDisplay = -newNumberInDisplay
            }
            if (newDisplayMode === DISPLAY_MODE.MEMORY) {
                newNumberInMemory = -newNumberInMemory
            }
            break;

        default:
            //pass

    } // end of switch()

    updatePoweredOn(newPoweredOn)
    updateOffClickCount(newOffClickCount)

    updateNumberInDisplay(newNumberInDisplay)
    updateDecimalInDisplay(newDecimalInDisplay)
    updateDecUsageInDisplay(newDecUsageInDisplay)

    updateNumberInMemory(newNumberInMemory)
    updateDecimalInMemory(newDecimalInMemory)
    updateDecUsageInMemory(newDecUsageInMemory)

    updateInputMode(newInputMode)
    updateDisplayMode(newDisplayMode)

    let finalDisplayNumber = "err"
    let number = 0;
    let decimal = 0;
    let decUsage = false;

    switch (newDisplayMode) {

        case DISPLAY_MODE.NUMBER:
            number = newNumberInDisplay;
            decimal = newDecimalInDisplay;
            decUsage = newDecUsageInDisplay;
            break;

        case DISPLAY_MODE.MEMORY:
            number = newNumberInMemory;
            decimal = newDecimalInMemory;
            decUsage = newDecUsageInMemory;
            break;

        default:
            //pass

    }

    if (decUsage) {
        if (number === 0) {
            let paddedZero = "0"
            if (decimal > 0) {
                paddedZero += "."
            }
            paddedZero += "0".repeat(decimal);
            finalDisplayNumber = paddedZero
        } else {
            finalDisplayNumber = number/(10**decimal);
        }
    } else {
        finalDisplayNumber = number;
    }

    if (newPoweredOn) {
        updateDisplayedNumber(finalDisplayNumber)
    } else {
        updateDisplayedNumber('')
    }

    console.log('newPoweredOn %s newOffClickCount %s', newPoweredOn, newOffClickCount)
    if (!newPoweredOn && (newOffClickCount % 3 === 0)) {
        alert("Try turning on the calculator. :-)")
    }

  } // end of pressed()

  return (
    <div className="App">
      <header className="App-header">
        <h1>Just Another Calculator</h1>

        <div className="CalculatorOutline">

          <Header />

          <Screen displayedNumber={displayedNumber} />

          <div className="ButtonArray">
            <Button label="AC" type="power" onClick={() => pressed("AC")} />
            <Button label="C" type="alt" onClick={() => pressed("C")} />
            <Button label="%" onClick={() => pressed("%")} />
            <Button label="+/-" onClick={() => pressed("+/-")} />

            <Button label="1" onClick={() => pressed("1")} />
            <Button label="2" onClick={() => pressed("2")} />
            <Button label="3" onClick={() => pressed("3")} />
            <Button label={`\u00F7`} onClick={() => pressed("/")} />

            <Button label="4" onClick={() => pressed("4")} />
            <Button label="5" onClick={() => pressed("5")} />
            <Button label="6" onClick={() => pressed("6")} />
            <Button label={`\u00D7`} onClick={() => pressed("*")} />

            <Button label="7" onClick={() => pressed("7")} />
            <Button label="8" onClick={() => pressed("8")} />
            <Button label="9" onClick={() => pressed("9")} />
            <Button label={`\u2013`} onClick={() => pressed("-")}  />

            <Button label="0" onClick={() => pressed("0")} />
            <Button label="." onClick={() => pressed(".")} />
            <Button label="=" onClick={() => pressed("=")} />
            <Button label={`\u002B`} onClick={() => pressed("+")} />
          </div>
        </div>
        <div class="return-link"><a href="https://sillyreactapps.com/">&laquo; Return to Silly React Apps</a></div>
      </header>
    </div>
  );
}

export default App;
