import React, { Component } from 'react';
import styles from "./Screen.module.css"

class Screen extends Component {

    render() {
        return (
            <div className={styles.OuterScreen}>
                <div className={styles.OuterScreen2}>
                    <div className={styles.Screen}>{this.props.displayedNumber}</div>
                </div>
            </div>
        )
    }
}

export default Screen;
