import React, { Component } from 'react';
import styles from "./Header.module.css"

class Header extends Component {

    render() {
        return (
          <div className={styles.Header}>
            <div className={styles.Text}>
              <div><strong>ASIO-CAY</strong></div>
              <div>HS-4G</div>
            </div>
            <div className={styles.SolarCell}>
              <div></div><div></div><div></div><div></div>
            </div>
          </div>
        )
    }
}

export default Header;
